import React from "react"
import Layout from "../components/Layout"

export default function impressum() {
  return (
    <Layout>
      <div className="container main-container">
        <p
          className="MsoNormal"
          style={{
            msoMarginTopAlt: "auto",
            msoMarginBottomAlt: "auto",
            msoOutlineLevel: 1,
          }}
        >
          <b>
            <span
              style={{
                fontSize: "24.0pt",

                msoFontKerning: "18.0pt",
                msoFareastLanguage: "DE",
              }}
            >
              Impressum
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{
            msoMarginTopAlt: "auto",
            msoMarginBottomAlt: "auto",
            msoOutlineLevel: 2,
          }}
        >
          <b>
            <span
              style={{
                fontSize: "18.0pt",

                msoFareastLanguage: "DE",
              }}
            >
              Angaben gemäß § 5 TMG:
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ msoMarginTopAlt: "auto", msoMarginBottomAlt: "auto" }}
        >
          <span
            style={{
              fontSize: "12.0pt",

              msoFareastLanguage: "DE",
            }}
          >
            Turnverein Lahr von 1846 e.V.
            <br />
            Gerichtsstr. 3<br />
            77933 Lahr
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            msoMarginTopAlt: "auto",
            msoMarginBottomAlt: "auto",
            msoOutlineLevel: 2,
          }}
        >
          <b>
            <span
              style={{
                fontSize: "18.0pt",

                msoFareastLanguage: "DE",
              }}
            >
              Vertreten durch:
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ msoMarginTopAlt: "auto", msoMarginBottomAlt: "auto" }}
        >
          <span
            style={{
              fontSize: "12.0pt",

              msoFareastLanguage: "DE",
            }}
          >
            Stefan Ehinger, Jürgen Kattinger, Steffen Laube, Claudia Michel,
            Jennifer Kramer
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            msoMarginTopAlt: "auto",
            msoMarginBottomAlt: "auto",
            msoOutlineLevel: 2,
          }}
        >
          <b>
            <span
              style={{
                fontSize: "18.0pt",

                msoFareastLanguage: "DE",
              }}
            >
              Kontakt:
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ msoMarginTopAlt: "auto", msoMarginBottomAlt: "auto" }}
        >
          <span
            style={{
              fontSize: "12.0pt",

              msoFareastLanguage: "DE",
            }}
          >
            Telefon: 07821/1247
            <br />
            E-Mail:{" "}
          </span>
          <a href="mailto:info@turnverein-lahr.de">
            <span
              style={{
                fontSize: "12.0pt",

                msoFareastLanguage: "DE",
              }}
            >
              info@turnverein-lahr.de
            </span>
          </a>
          <span
            style={{
              fontSize: "12.0pt",

              msoFareastLanguage: "DE",
            }}
          ></span>
        </p>
        <p
          className="MsoNormal"
          style={{ msoMarginTopAlt: "auto", msoMarginBottomAlt: "auto" }}
        >
          <b>
            <span
              style={{
                fontSize: "18.0pt",

                msoFareastLanguage: "DE",
              }}
            >
              Registereintrag – Eintragung im Vereinsregister:
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ msoMarginTopAlt: "auto", msoMarginBottomAlt: "auto" }}
        >
          <span
            style={{
              fontSize: "12.0pt",

              msoFareastLanguage: "DE",
            }}
          >
            Registergericht: Amtsgericht Freiburg
            <br />
            Vereins-Registernummer: 390 359
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            msoMarginTopAlt: "auto",
            msoMarginBottomAlt: "auto",
            msoOutlineLevel: 2,
          }}
        >
          <b>
            <span
              style={{
                fontSize: "18.0pt",

                msoFareastLanguage: "DE",
              }}
            >
              Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ msoMarginTopAlt: "auto", msoMarginBottomAlt: "auto" }}
        >
          <span
            style={{
              fontSize: "12.0pt",

              msoFareastLanguage: "DE",
            }}
          >
            Stefan Ehinger, Jürgen Kattinger, Steffen Laube, Claudia Michel,
            Jennifer Kramer
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            msoMarginTopAlt: "auto",
            msoMarginBottomAlt: "auto",
            msoOutlineLevel: 2,
          }}
        >
          <b>
            <span
              style={{
                fontSize: "18.0pt",

                msoFareastLanguage: "DE",
              }}
            >
              Haftung für Inhalte
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ msoMarginTopAlt: "auto", msoMarginBottomAlt: "auto" }}
        >
          <span
            style={{
              fontSize: "12.0pt",

              msoFareastLanguage: "DE",
            }}
          >
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ msoMarginTopAlt: "auto", msoMarginBottomAlt: "auto" }}
        >
          <span
            style={{
              fontSize: "12.0pt",

              msoFareastLanguage: "DE",
            }}
          >
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            msoMarginTopAlt: "auto",
            msoMarginBottomAlt: "auto",
            msoOutlineLevel: 2,
          }}
        >
          <b>
            <span
              style={{
                fontSize: "18.0pt",

                msoFareastLanguage: "DE",
              }}
            >
              Haftung für Links
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ msoMarginTopAlt: "auto", msoMarginBottomAlt: "auto" }}
        >
          <span
            style={{
              fontSize: "12.0pt",

              msoFareastLanguage: "DE",
            }}
          >
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ msoMarginTopAlt: "auto", msoMarginBottomAlt: "auto" }}
        >
          <span
            style={{
              fontSize: "12.0pt",

              msoFareastLanguage: "DE",
            }}
          >
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            msoMarginTopAlt: "auto",
            msoMarginBottomAlt: "auto",
            msoOutlineLevel: 2,
          }}
        >
          <b>
            <span
              style={{
                fontSize: "18.0pt",

                msoFareastLanguage: "DE",
              }}
            >
              Urheberrecht
            </span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ msoMarginTopAlt: "auto", msoMarginBottomAlt: "auto" }}
        >
          <span
            style={{
              fontSize: "12.0pt",

              msoFareastLanguage: "DE",
            }}
          >
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ msoMarginTopAlt: "auto", msoMarginBottomAlt: "auto" }}
        >
          <span
            style={{
              fontSize: "12.0pt",

              msoFareastLanguage: "DE",
            }}
          >
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </span>
        </p>
        <p>
          <strong>
            <span style={{ fontSize: "18.0pt" }}>Datenschutz</span>
          </strong>
          <span style={{ fontSize: "18.0pt" }}></span>
        </p>
        <p>
          Die Nutzung unserer Webseite ist in der Regel ohne Angabe
          personenbezogener Daten möglich. Soweit auf unseren Seiten
          personenbezogene Daten (beispielsweise Name, Anschrift oder
          eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
          auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
          Zustimmung nicht an Dritte weitergegeben.{" "}
        </p>
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.{" "}
        </p>
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
          angeforderter Werbung und Informationsmaterialien wird hiermit
          ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
          ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
          von Werbeinformationen, etwa durch Spam-Mails, vor.
        </p>
        <h1></h1>
      </div>
    </Layout>
  )
}
