import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FiFacebook, FiInstagram } from "react-icons/fi"
import "../../assets/style/components/_footer.scss"
class Footer extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <footer className="footer">
        <div className="container main-container">
          <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-6">
              <Link to="/" className="logo-link">
                <img
                  alt="tv-lahr"
                  src="https://www.datocms-assets.com/77261/1661699345-logo.png"
                />
                Turnverein Lahr
              </Link>
              <p style={{margin: "8px 0 0 0"}}>Kontakt:</p>
              <Link to="mailto:info@turnverein-lahr.de">
                Mail: info@turnverein-lahr.de
              </Link><br/>
              <Link to="tel:078211247">
                Tel.: 07821/1247
              </Link>
              <p style={{marginTop: "8px"}}>Öffnungszeiten:</p>
              <p>
                Dienstag: <br />
                15:00 – 18:00 Uhr
                <br />
                Freitag:
                <br />
                9:00 – 11:00 Uhr
                <br />
              </p>
              <p>
                Folgen Sie uns auf{" "}<br/>
                <a
                  href="https://www.instagram.com/tvlahr1846/"
                  target="_blank"
                >
                  <FiInstagram style={{ width: "30px", height: "30px" }} />
                </a>{"   "}
                <a
                  href="https://www.facebook.com/turnvereinlahr/"
                  target="_blank"
                >
                  <FiFacebook style={{ width: "30px", height: "30px" }} />
                </a>
              </p>
            </div>
            <div className="col-xs-12 col-md-2 col-lg-2">
              <h5>Verein</h5>
              <ul>
                <li>
                  <Link to="/news/">News</Link>
                </li>
                <li>
                  <Link to="/verein/">Vorstand</Link>
                </li>
                {/* <li>
                  <Link to="/geschaeftsstelle-und-sportstaetten/">Geschäftsstelle & Sportstätten</Link>
                </li> */}
                {/* <li>
                  <Link to="/chronik/">Chronik</Link>
                </li> */}
                <li>
                  <Link to="/downloads/">Downloads</Link>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-md-2 col-lg-2">
              <h5>Sportarten</h5>
              <ul>
                <li>
                  <Link to="/sportarten/">Alle Sportarten</Link>
                </li>
                {/* <li>
                  <Link to="/wochenplan/">Wochenplan</Link>
                </li> */}
              </ul>
            </div>
            {/* <div className="col-xs-12 col-md-2 col-lg-2">
              <h5>Wettkampf</h5>
              <ul>
                <li>
                  <Link to="/basketball/">Basketball</Link>
                </li>
                <li>
                  <Link to="/dance/">Dance</Link>
                </li>
              </ul>
            </div> */}
            <div className="col-xs-12 col-md-2 col-lg-2">
              <h5>Rechtliches</h5>
              <ul>
                <li>
                  <Link to="/impressum/">Impressum</Link>
                </li>
                <li>
                  <Link to="/datenschutz/">Datenschutz</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
export default Footer
