/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
// import Seo from "../seo"
// import "./layout.css"
import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children, pageContext }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const title = pageContext && pageContext.seo && pageContext.seo.title
  const image = pageContext && pageContext.seo && pageContext.seo.image
  const description =
    pageContext && pageContext.seo && pageContext.seo.description
  const robots = pageContext && pageContext.seo && pageContext.seo.robots
  const path = pageContext && pageContext.seo && pageContext.seo.path
  const lang = pageContext && pageContext.seo && pageContext.seo.lang

  return (
    <>
      {/* <Seo
        title={title}
        description={description}
        path={path}
        robots={robots}
        image={image}
        lang={lang}
      /> */}
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main>{children}</main>
        <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
