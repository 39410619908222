import React from 'react'
import PropTypes from "prop-types"
import "../../assets/style/components/_button.scss"
import Arrow from "../../assets/svg/arrow-right.svg"
export default function Button({type, onClick, children, url, additionalClassName, style, download, withoutArrow}) {
  return url !== undefined ? (
    <a href={url} className={`${additionalClassName || ""} wis-btn wis-btn-${type}`} download={download} style={style} onClick={onClick}>{children}{!withoutArrow && <Arrow/>}</a>
  ) : (
    <button className={`${additionalClassName || ""} wis-btn wis-btn-${type}`} style={style} onClick={onClick}>{children}</button>
  )
}
Button.propTypes = {
    additionalClassName: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.oneOf(["primary", "secondary", "link"]),
    onClick: PropTypes.func,
    url: PropTypes.string,
    download: PropTypes.bool,
    withoutArrow: PropTypes.bool,
}
Button.defaultProps = {
    type: "primary"
}